@import url('https://fonts.googleapis.com/css?family=Homemade+Apple|Lora|Oswald'); /* font-family: 'Lora', serif;  font-family: 'Homemade Apple', cursive; */
@font-face {
    font-family: 'Optima';
    src: url('../fonts/Optima-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Optima-Regular.woff') format('woff'),
    url('../fonts/Optima-Regular.ttf')  format('truetype'),
    url('../fonts/Optima-Regular.svg#Optima-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Optima';
    src: url('../fonts/Optima-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Optima-Bold.woff') format('woff'),
    url('../fonts/Optima-Bold.ttf')  format('truetype'),
    url('../fonts/Optima-Bold.svg#Optima-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
.overlay_rotate {
    display: none;
    position: fixed;
    top: 0;
    background-color: #333;
    color: #000;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    z-index: 777777777 !important;
}
.pr{position: relative;}
.img-center-cuvees{
    position: absolute;
    top: calc(50% - -1px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    z-index: 1;
}
.img-center-5{
    position: absolute;
    top:calc(50% - 0px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 0px);
    z-index: 1;
}
.img-center-pola{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(8deg);
    width: 70%;
    z-index: 1;
}
.iconContainer {
    width: 300px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -100px;
}
.iconContainer p {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 20px;
    opacity: 0;
}
.phone {
    position: relative;
    left: 50%;
    margin-left: -80px;
    width: 0;
    height: 0;
    border: 0 solid #000;
    background: #a9a9a9;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom:45px;
}
.phone i {
    text-align: center;
    width: 100%;
    line-height: 120px;
    font-size: 50px;
    color: #454545;
    opacity: 0;
}
@media only screen and (orientation: landscape) and (max-width: 820px) {
    .overlay_rotate {
        display: block;
        z-index:666;
    }
    .overlay_rotate .phone {
        -webkit-animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
        animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
    }
    .overlay_rotate .phone i {
        -webkit-animation: fadeIn .5s .8s forwards ease;
        animation: fadeIn .5s .8s forwards ease;
    }
    .overlay_rotate .iconContainer p {
        -webkit-animation: fadeIn .5s 1.3s forwards ease;
        animation: fadeIn .5s 1.3s forwards ease;
    }
}
@-webkit-keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@-webkit-keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@-webkit-keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@-webkit-keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
@keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
body {
    font-family: 'Lora', serif;
    font-size: 15px;
    color: #443937;
}
.menu a {
    font-family: 'Optima', cursive;
    font-size: 1.3em;
    color: #5d5352;
}
p {
    font-family: 'Optima', cursive;
}
a, a:focus, a:hover, a.active {
    color: #443937;
    text-decoration: none;
}
h2, h1 {
    font-family: 'Homemade Apple', cursive;
    line-height: 1.6;
    font-size: 1.6em;
}
h3 {
    font-weight: bold;
    font-size: 1.7vw;
}
.flex-grow {
    flex: 1 0 auto;
}
.abus { /* OSWALD ? */
    font-family: 'Oswald', sans-serif;
    background-color: #f6f6f6;
    color: #000;
    /*text-transform: uppercase;*/
    padding-top: 1em;
    z-index: 777;
    position: relative;
}
.abus p {
    font-size: 13px;
}
.uppercase {
    text-transform: uppercase;
}
.burger {
    cursor: pointer;
    position: fixed;
    right: 1em;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    bottom: 1em;
    border-radius: 60px;
    height: 60px;
    background-color: white !important;
    z-index: 7777;
    width: 60px;
    background: url('../images/home/burger.svg') 50% 50% no-repeat;
    background-size: 30px;
}
.burger span {
    display: none;
}
.triman {
    width: 55px;
}
nav {
    transition: all 300ms ease;
    background-color: #fff;
    text-align: center;
    position: fixed;
    z-index: -777;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    left: 0;
    top: 0;
}
nav.on {
    opacity: 1;
    z-index: 7777;
}
nav ul li {
    list-style-type: none;
}
.g-recaptcha { visibility: hidden; height: 0; }
.close {
    background: #5d5352 url('../images/home/close.svg') 50% 50% no-repeat;
    background-size: 40%;
    opacity: 1 !important;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    cursor: pointer;
    position: fixed;
    right: 1em;
    bottom: 1em;
    border-radius: 60px;
    height: 60px;
    width: 60px;
    font-size: unset;
}
.close span {
    display: none;
}
.box {
    min-height: 100%;
}
.socials img { width: 36px; }
footer { font-family: 'Oswald'; background-color: #443937; }
footer a, footer a:hover, footer a:active { color: white; }
#item1 {
    background-image: url("../images/mobile/home/home-1-mobile.png");
    background-size: cover;
    background-position: 100% 0;
}
#item2 {
    background-image: url("../images/mobile/home/home-2-mobile.png");
    background-size: cover;
    background-position: 0 0;
}
.item-2 {
    position: absolute;
    right: 10vw;
    bottom: 24vh;
}
#item3 {
    background-image: url("../images/mobile/home/home-3-mobile.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.item-3-1 {
    position: absolute;
    top: 0;
}
.item-3-2 {
    position: absolute;
    bottom: 1vh;
}
.block-invisible {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20%;
    left: 20%;
}
#item4 {
    background-image: url("../images/mobile/home/home-4-mobile.png");
    background-size: cover;
    background-position: center center;
}
#item4 .position-relative div {
    position: absolute;
    top: 2vh;
    left: 10%;
}
.mentions h1 {
    font-family: inherit;
}
.btn-outline-light {
    border: 1px solid #676869;
    border-radius: 24px;
    padding: .2em 2em;
    text-transform: uppercase;
    font-weight: bold;
    color: #676869;
    font-size: .7em;
}
@supports (-webkit-appearance:none) {
    body.vh-100 {
        height: calc(100vh - 56px) !important;
    }
}
.limit-img {
    max-height: 70%;
}
.carousel-control-next {
    width: inherit;
    opacity: 1;
    position: fixed;
    top: 6em;
    bottom: 6em;
}
.carousel-control-prev {
    width: inherit;
    opacity: 1;
    position: fixed;
    top: 6em;
    bottom: 6em;
}
/* Verify age */
.verify {
    transition: all 700ms ease;
    background-color: #fff;
    z-index: 77777777;
    position: fixed;
    padding: 15px;
    opacity: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.verify.off {
    opacity: 0;
    z-index: -7;
}
.verify img {
    max-width: 300px;
}
.verify p {
    max-width: 500px;
    margin: auto
}
.verify a {
    color: #000;
}
.verify label {
    font-weight: bold;
    color: #000;
}
.verify h1 {
    color: #000;
    font-weight: normal;
    text-shadow: none;
}
.btn.btn-verify {
    background-color: #000;
    border-color: #000;
    color: #fff !important;
    text-transform: uppercase;
    padding: .3em 2.5em;
    border-radius: 30px;
}
.btn-verify:hover, .btn-verify:active, .btn-verify:focus {
    border-color: #000;
}
/* Fin Verify age */
.carousel-control-next .carousel-control-next-icon, .carousel-control-prev .carousel-control-prev-icon {
    background: #fff url('../images/home/arrow.svg') 50% 50% no-repeat;
    background-size: 15px;
    border-radius: 30px 0 0 30px;
    box-shadow: 0 0 50px rgba(0,0,0,.3);
    padding: .1em 3em .1em .1em;
    min-height: 50px;
}
.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    opacity: 1;
}
.carousel-control-prev .carousel-control-prev-icon {
    transform: rotate(180deg);
}
.carousel {
    height: 80%;
}
.carousel-item {
    height: 100%;
}
.vh-20 {
    height: 20%;
}
/*.home .carousel-item {*/
/*    min-height: 750px;*/
/*}*/
.carousel-inner .container-fluid {
    padding: 0;
    min-height: 100%;
}
.carousel-indicators li {
    border-radius: 10px;
    height: 10px;
    opacity: 1;
    width: 10px;
    border: 1px solid #443937;
}
.carousel-indicators .active {
    background-color: #443937;
}
.carousel-indicators {
    bottom: 8vh;
}
.bot {
    bottom: 0;
}
.art h1 {
    font-family: inherit;
    font-size:1.2em;
    margin-bottom: 0px;
}
.art h4 {
    font-weight: bold;
    margin: 1.2em 0 .8em 0;
}
.art div {
    position: relative;
}
.mentions h4 {
    font-size: 1.2em;
}
.art > div:first-child {
    background-image: url('../images/mobile/art/art-3-mobile.png');
    background-repeat: no-repeat;
    background-size: 18%;
    background-position: right center;
}
.art > div:nth-child(2) {
    background-image: url('../images/mobile/art/art-4-mobile.png');
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: left top;
}
.art > div:nth-child(3) {
    background-image: url('../images/mobile/art/art-5-mobile.png');
    background-repeat: no-repeat;
    background-size: 18%;
    background-position: right top;
}
.art > div:nth-child(4) {
    background-image: url('../images/mobile/art/art-6-mobile.png');
    background-repeat: no-repeat;
    background-size: 18%;
    background-position: left top;
}
@keyframes expand {
    from{
        top:-300px;opacity:0
    }
    to {
        top:0;opacity:1
    }
}
.collapse {
    animation: expand .8s;
}
.art h4 span {
    font-weight: normal;
    font-size: .8em;
    display: block;
}
.cuvees-title {
    text-align: center;
    line-height: 1em;
}
.cuvees-title span {
    font-family: 'Lora', serif;
    font-size: .6em;
}
.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}
form input, form textarea {
    width: 100%;
    margin: 1.2em 0;
}
input[type="text"], input[type="email"], input[type="tel"], textarea, select {
    display: block;
    border: 1px solid #fff;
    border-bottom: 1px solid #a7a7a7;
    color: #7d7d7d;
}
input[type="submit"] {
    font-family: 'Lora', serif;
    width: unset;
    border: 1px solid #858687;
    background-color: white;
    padding: .3em 4em;
    text-transform: uppercase;
    border-radius: 20px;
    font-weight: bold;
    color: #443937;
}
.contact-3 {
    max-width: 70%;
}
